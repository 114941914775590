import {
  ReadCourseResponse,
  Course,
  CreateCourseQueryArgs,
} from './api-course.type';

export const transformResponseReadCourse = (
  response: ReadCourseResponse,
): Course => {
  const { lessons, ...courseRest } = response;

  const lessonsTransformed = lessons.map((lesson) => {
    const { allSlidesPassed, assignmentsPassed, ...lessonRest } = lesson;
    return {
      ...lessonRest,
      areAllSlidesSeen: allSlidesPassed,
      areAllAssignmentsPassed: assignmentsPassed,
    };
  });

  return {
    ...courseRest,
    lessons: lessonsTransformed,
    lessonIds: response.lessons.map((lesson) => lesson.id),
  };
};

export const transformUpdateCoursePayload = (
  payload: CreateCourseQueryArgs['payload'],
): CreateCourseQueryArgs['payload'] => {
  const { price, ...rest } = payload;

  if (price && parseFloat(price)) {
    return payload;
  }

  return rest;
};
