import Bugsnag from '@bugsnag/browser';
import { produce } from 'immer';
import {
  lessonStorageService,
  STORAGE_USE_ERROR_MESSAGE,
} from '../../../storage';
import { courseStorageService } from '../../../storage/course';
import { elementStorageService } from '../../../storage/element';
import { enqueueSnackbar } from '../../../utils/snackbar-helper';
import { isUserLoggedIn } from '../../../providers/user';
import { transformResponseReadCourse } from './api-course.transformer';
import { Course, CourseInList, ReadCoursesResponse } from './api-course.type';

export const transformStorageResponseReadCourse = async (
  course: ReturnType<typeof transformResponseReadCourse>,
): Promise<Course> => {
  if (isUserLoggedIn()) {
    return course;
  }

  try {
    const offlineCourse = await courseStorageService.readOne(course.id);

    if (!offlineCourse) {
      return course;
    }

    const transformedCourse = produce<Course>(course, (draft) => {
      if (!draft.courseSubscription) {
        draft.courseSubscription = {
          id: 'course-subscription',
          currentLesson: null,
        };
      }

      draft.courseSubscription.currentLesson = offlineCourse.selectedLessonId;
    });

    const lessons = await Promise.all(
      course.lessons.map(async (lesson) => {
        const offlineLesson = await lessonStorageService.readOne(lesson.id);

        if (!offlineLesson) {
          return lesson;
        }

        const { slideIds, seenSlideIds } = offlineLesson;

        const seenSlideIdsSet = new Set(seenSlideIds);

        const areAllSlidesSeen = slideIds.every((slideId) =>
          seenSlideIdsSet.has(slideId),
        );

        const slideElements = await elementStorageService.readMany({
          lessonId: lesson.id,
        });
        const areAllAssignmentsPassed = slideElements.every(
          (element) => element.isAssignmentPassed,
        );

        return {
          ...lesson,
          areAllSlidesSeen,
          areAllAssignmentsPassed,
        };
      }),
    );

    return {
      ...transformedCourse,
      lessons,
    };
  } catch (error) {
    if (error instanceof Error) {
      Bugsnag.notify(error);
    }
    enqueueSnackbar(STORAGE_USE_ERROR_MESSAGE, { variant: 'error' });

    return course;
  }
};

export const transformStorageResponseReadCourses = async (
  response: ReadCoursesResponse,
): Promise<ReadCoursesResponse> => {
  if (isUserLoggedIn()) {
    return response;
  }

  try {
    const { courses } = response;
    const offlineCourses = await courseStorageService.readMany();

    const transformedCourses = courses.map((courseItem) =>
      produce<CourseInList>(courseItem, (draft) => {
        if (!draft.courseSubscription) {
          draft.courseSubscription = {
            id: 'course-subscription',
            currentLesson: null,
          };
        }

        const offlineCourse = offlineCourses.find((oc) => oc.id === draft.id);

        draft.courseSubscription.currentLesson =
          offlineCourse?.selectedLessonId || null;
      }),
    );

    return {
      ...response,
      courses: transformedCourses,
    };
  } catch (error) {
    if (error instanceof Error) {
      Bugsnag.notify(error);
    }
    enqueueSnackbar(STORAGE_USE_ERROR_MESSAGE, { variant: 'error' });

    return response;
  }
};
