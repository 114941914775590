import zod from './zod.json';
import common from './common.json';
import auth from './auth.json';
import profile from './profile.json';
import lesson from './lesson.json';
import course from './course.json';

export const localeEN = {
  zod,
  common,
  auth,
  profile,
  lesson,
  course,
};
