export const invariant: <T>(
  value: T | null | undefined,
  errorMessage?: string,
) => asserts value is T = (val, errorMessage) => {
  if (val) {
    return true;
  }

  throw new Error(errorMessage || 'Invariant violation');
};
