import { createApi } from '@reduxjs/toolkit/query/react';
import axios, { AxiosError } from 'axios';
import Bugsnag from '@bugsnag/browser';
import { storageApi } from '../storage/storage-api';
import { isUserLoggedIn } from '../providers/user/is-user-logged-in';
import { enqueueSnackbar } from '../utils/snackbar-helper';
import { getResponseErrorMessage } from '../utils/get-response-error-message';
import { BaseQueryFuncOptions, Response } from './api.type';
import { BASE_URL, tagTypes } from './api-tags';

export const api = createApi({
  tagTypes,
  baseQuery: async ({
    url: endpointUrl,
    path,
    serverErrorsToIgnore = [],
    method,
    params,
    payload,
    headers,
  }: BaseQueryFuncOptions) => {
    if (!BASE_URL) {
      // eslint-disable-next-line no-console
      throw new Error(
        'BASE_URL is not defined. Ensure the relevant environment variable is set.',
      );
    }

    const isLoggedIn = isUserLoggedIn();
    const isRequestMutation = method !== 'get';
    const guestUserMutationAllowedURLs = [
      '/auth/register',
      '/auth/login',
      '/auth/resendVerificationEmail',
      '/auth/passwordReset',
      '/auth/passwordUpdate',
    ];
    const shouldSaveProgressLocally =
      !guestUserMutationAllowedURLs.some((url) =>
        endpointUrl.startsWith(url),
      ) &&
      !isLoggedIn &&
      isRequestMutation;

    if (shouldSaveProgressLocally) {
      if (!path) {
        const error = new Error('Guest user mutation request without path');
        Bugsnag.notify(error);
        throw error;
      }

      try {
        const result = await storageApi.request(path, {
          params,
          payload,
        });

        return { data: result };
      } catch (error) {
        return { error };
      }
    }

    const axiosClient = axios.create({
      withCredentials: true,
      baseURL: BASE_URL,
    });

    axiosClient.interceptors.response.use(
      (response) => response,
      (error) => {
        const shouldDisplayError =
          error instanceof AxiosError &&
          error.response &&
          !serverErrorsToIgnore.includes(error.response?.status);

        if (shouldDisplayError) {
          enqueueSnackbar(getResponseErrorMessage(error), { variant: 'error' });
        }

        throw error;
      },
    );

    axiosClient.interceptors.request.use(
      (config) => config,
      (error) => {
        enqueueSnackbar(getResponseErrorMessage(error), { variant: 'error' });
        throw error;
      },
    );

    try {
      const { data: axiosData } = await axiosClient<Partial<Response>>(
        endpointUrl,
        {
          method,
          data: payload,
          headers,
        },
      );

      return { data: axiosData?.data || axiosData };
    } catch (error) {
      return { error };
    }
  },
  endpoints: () => ({}),
});
