import React from 'react';
import { Elements as StripeProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { PaymentProviderProps } from './payment.type';
import { PaymentContext } from './payment-context';
import { usePaymentInternal } from './lib/use-payment-internal/use-payment-internal';

// Stripe tracks user progress (on every page) and they claim they do it to detect fraud.
// they are quite vague about this which causes privacy concerns. see: https://mtlynch.io/stripe-recording-its-customers/
// for this reason, we disable their tracking mechanism.
loadStripe.setLoadParameters({ advancedFraudSignals: false });
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_API_PUBLIC_KEY || '',
);

// nested provider is essential so that useStripe is callable (from inside the stripe provider)
const PaymentProviderInStripeProvider = ({
  children,
}: PaymentProviderProps) => {
  const payment = usePaymentInternal();

  return (
    <PaymentContext.Provider value={payment}>
      {children}
    </PaymentContext.Provider>
  );
};

export const PaymentProvider = ({ children }: PaymentProviderProps) => (
  <StripeProvider stripe={stripePromise}>
    <PaymentProviderInStripeProvider>
      {children}
    </PaymentProviderInStripeProvider>
  </StripeProvider>
);
