import React from 'react';
import { useUserInternal } from './lib/use-user-internal';
import { UserContext } from './user-context';
import { UserProviderProps } from './user.type';

export const UserProvider = ({ children }: UserProviderProps) => {
  const user = useUserInternal();

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};
