export const BASE_URL = process.env.REACT_APP_SERVER_URL;

const API_TAG_TYPES = {
  course: 'course',
  courses: 'courses',
  lesson: 'lesson',
  user: 'user',
  accessCode: 'accessCode',
  purchase: 'purchase',
  shareUrl: 'shareUrl',
  shareContent: 'shareContent',
  userElementValue: 'userElementValue',
};

export const API_TAGS = {
  course: (courseId: string) => ({
    type: API_TAG_TYPES.course,
    id: courseId,
  }),
  courses: () => ({
    type: API_TAG_TYPES.courses,
  }),
  lesson: (lessonId: string) => ({
    type: API_TAG_TYPES.lesson,
    id: lessonId,
  }),
  user: (userId: string) => ({
    type: API_TAG_TYPES.user,
    id: userId,
  }),
  accessCode: (courseId: string) => ({
    type: API_TAG_TYPES.accessCode,
    id: courseId,
  }),
  purchase: () => ({
    type: API_TAG_TYPES.purchase,
  }),
  shareUrl: (slideElementId: string) => ({
    type: API_TAG_TYPES.shareUrl,
    id: slideElementId,
  }),
  userElementValue: (userElementId: string) => ({
    type: API_TAG_TYPES.userElementValue,
    id: userElementId,
  }),
  shareContent: (shareUrl: string) => ({
    type: API_TAG_TYPES.shareContent,
    id: shareUrl,
  }),
};

export const tagTypes = Object.values(API_TAG_TYPES);
