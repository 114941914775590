import { useState } from 'react';
import { useSessionStorage } from 'react-use';
import { AxiosError } from 'axios';
import {
  useLoginUserMutation,
  useLogoutUserMutation,
  useUpdateUserPasswordMutation,
  useResetUserPasswordMutation,
  useRegisterUserMutation,
  useLazyReadUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  usePersistGuestDataMutation,
  useResendEmailVerificationMutation,
} from '../api/user';
import { storageApi } from '../../../../storage';
import { UseUserInternalReturn } from './use-user-internal.type';

export const useUserInternal = (): UseUserInternalReturn => {
  const [isLoggedIn, setIsLoggedIn] = useSessionStorage('isLoggedIn', false);
  const [data, setData] = useState<UseUserInternalReturn['account']['data']>();

  const [loginInternal] = useLoginUserMutation();
  const [logoutInternal] = useLogoutUserMutation();
  const [resendVerificationEmailInternal] =
    useResendEmailVerificationMutation();
  const [updatePasswordInternal] = useUpdateUserPasswordMutation();
  const [resetUserPasswordInternal] = useResetUserPasswordMutation();
  const [createAccountInternal] = useRegisterUserMutation();
  const [
    readAccountInternal,
    { isLoading: isReadAccountLoading, error: readAccountError },
  ] = useLazyReadUserQuery();
  const [updateAccountInternal] = useUpdateUserMutation();
  const [deleteAccountInternal] = useDeleteUserMutation();
  const [persistGuestDataInternal] = usePersistGuestDataMutation();

  const readAccount: UseUserInternalReturn['account']['read'] = async () => {
    if (data) {
      return;
    }

    const response = await readAccountInternal();

    if (
      response.data ||
      (response.error instanceof AxiosError &&
        response.error.response?.status !== 401)
    ) {
      setIsLoggedIn(true);
      setData(response.data);
    } else {
      setIsLoggedIn(false);
      setData(undefined);
    }
  };

  const login: UseUserInternalReturn['auth']['login'] = async (args) => {
    const response = await loginInternal(args).unwrap();
    await readAccount();
    return response;
  };

  const logout: UseUserInternalReturn['auth']['logout'] = async (args) => {
    // it's important to update the state before calling logout because backend redirects the client to the login page
    // upon successful logout and the session storage for isLoggedIn won't be updated
    setIsLoggedIn(false);
    setData(undefined);

    // in the scenario that the logged in user verified their account on a different browser as the one they are logged in on AND
    // the browser they are logged on was previously used by them (or someone else) as guest user, then we need to clear the guest progress
    //  on the browser so that upon logout they won't be confused by why they still see some (guest) progress
    storageApi.clear();

    const response = await logoutInternal(args).unwrap();
    return response;
  };

  const resendVerificationEmail: UseUserInternalReturn['auth']['resendVerificationEmail'] =
    async (args) => resendVerificationEmailInternal(args).unwrap();

  const updatePassword: UseUserInternalReturn['password']['update'] = async (
    args,
  ) => updatePasswordInternal(args).unwrap();

  const resetUserPassword: UseUserInternalReturn['password']['reset'] = async (
    args,
  ) => resetUserPasswordInternal(args).unwrap();

  const createAccount: UseUserInternalReturn['account']['create'] = async (
    args,
  ) => createAccountInternal(args).unwrap();

  const updateAccount: UseUserInternalReturn['account']['update'] = async (
    args,
  ) => updateAccountInternal(args).unwrap();

  const deleteAccount: UseUserInternalReturn['account']['delete'] = async (
    args,
  ) => {
    const response = deleteAccountInternal(args).unwrap();
    setIsLoggedIn(false);
    setData(undefined);
    return response;
  };

  const persistGuestData: UseUserInternalReturn['account']['persistGuestData'] =
    async (args) => persistGuestDataInternal(args).unwrap();

  return {
    auth: {
      isLoggedIn,
      login,
      logout,
      resendVerificationEmail,
    },
    password: {
      update: updatePassword,
      reset: resetUserPassword,
    },
    account: {
      data,
      read: readAccount,
      create: createAccount,
      update: updateAccount,
      delete: deleteAccount,
      persistGuestData,
      isLoading: {
        read: isReadAccountLoading,
      },
      error: {
        read: readAccountError as AxiosError,
      },
    },
  };
};
