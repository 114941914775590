import { debounce } from 'lodash';
import React, { useState, Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Typography, useTheme } from '@mui/material';
import './assets/styles/main.css';
import './assets/styles/index.css';
import { z } from 'zod';
import { makeZodI18nMap } from 'zod-i18n-map';
import { InitialLoading } from './components/initial-loading';
import { CookiePopUp } from './components/common/cookie-pop-up';
import { clearPyodideFlags } from './utils/clear-pyodide-flags';
import { PaymentProvider } from './providers/payment';
import { isInsideIframe } from './utils/is-inside-iframe';
import { isTheParentSameDomain } from './utils/is-the-parent-same-domain';
import { MaintenanceDialog } from './components/maintenance-dialog';
import { useAppTranslation } from './locales/i18n';
import { UserProvider } from './providers/user';
import { AppRoutes } from './routes.component';

clearPyodideFlags();

export const App = () => {
  const theme = useTheme();

  const searchParams = new URLSearchParams(window.location.search);
  const [isRecordMode] = useState<boolean>(
    searchParams.get('mode') === 'record',
  );
  const [isShowcaseMode] = useState<boolean>(
    searchParams.get('mode') === 'showcase',
  );
  const isSpecialMode = isRecordMode || isShowcaseMode;
  const [hasAcceptedCookies, setHasAcceptedCookies] = useState<boolean>(
    document.cookie.indexOf('acceptedCookies') >= 0,
  );

  const { t } = useAppTranslation();
  z.setErrorMap(
    makeZodI18nMap({
      t,
      handlePath: {
        ns: ['zod'],
      },
    }),
  );

  useEffect(() => {
    if (isRecordMode) {
      return undefined;
    }

    const checkDevice = () => {
      // Due to plausible loading earlier.
      setTimeout(() => {
        // FIXME (478): resolve the ESLint error and remove the eslint-disable comment
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        window.plausible('screen_properties', {
          props: {
            screen_width: window.innerWidth,
            screen_height: window.innerHeight,
            has_fine_pointer: window.matchMedia('(any-pointer: fine)').matches,
          },
        });
      }, 1000);
    };

    // FIXME (478): resolve the ESLint error and remove the eslint-disable comment
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    window.plausible =
      window.plausible ||
      // NOTE: the use of `arguments` on the function body requires us to use a function declaration here
      // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
      function plausibleFallback() {
        // FIXME (478, 486): resolve the ESLint error and remove the eslint-disable comment
        // eslint-disable-next-line prefer-rest-params, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-assignment
        (window.plausible.q = window.plausible.q || []).push(arguments);
      };

    const debouncedCheckDevice = debounce(checkDevice, 200);
    const pointerCheck = window.matchMedia('(any-pointer: fine)');
    debouncedCheckDevice();
    window.addEventListener('resize', debouncedCheckDevice);
    pointerCheck.addEventListener('change', debouncedCheckDevice);

    return () => {
      window.removeEventListener('resize', debouncedCheckDevice);
      pointerCheck.removeEventListener('change', debouncedCheckDevice);
    };
  }, [theme.breakpoints.values.md, isRecordMode]);

  if (isInsideIframe() && !isTheParentSameDomain()) {
    return (
      <Typography>This content cannot be displayed in an iframe.</Typography>
    );
  }

  return (
    <BrowserRouter>
      <UserProvider>
        <PaymentProvider>
          <DndProvider backend={HTML5Backend}>
            <Helmet>
              {process.env.REACT_APP_PLAUSIBLE_HOST && (
                <script
                  defer
                  data-domain={process.env.REACT_APP_PLAUSIBLE_HOST}
                  src='https://plausible.auditorium.ai/js/script.js'
                />
              )}
              <script>
                {`window.plausible = window.plausible ||
						function () {
							(window.plausible.q = window.plausible.q || []).push(arguments);
						};`}
              </script>
            </Helmet>
            {!isSpecialMode && (
              <CookiePopUp
                hasAcceptedCookies={hasAcceptedCookies}
                setHasAcceptedCookies={setHasAcceptedCookies}
              />
            )}
            <MaintenanceDialog />
            <Suspense fallback={<InitialLoading />}>
              <AppRoutes />
            </Suspense>
          </DndProvider>
        </PaymentProvider>
      </UserProvider>
    </BrowserRouter>
  );
};
