import { api } from '../../api';
import { API_TAGS } from '../../api-tags';
import {
  transformStorageResponseReadCourse,
  transformStorageResponseReadCourses,
} from './api-course.storage-transformer';
import {
  transformResponseReadCourse,
  transformUpdateCoursePayload,
} from './api-course.transformer';
import { updateStorageReadCourse } from './api-course.storage-updater';
import {
  CreateCourseQueryArgs,
  CreateCourseResponse,
  DeleteCourseQueryArgs,
  DeleteCourseResponse,
  ReadCourseQueryArgs,
  ReadCourseResponse,
  ReadCoursesResponse,
  ReorderLessonsQueryArgs,
  ReorderLessonsResponse,
  SubscribeToCourseQueryArgs,
  SubscribeToCourseResponse,
  UpdateCourseCurrentLessonArgs,
  UpdateCourseCurrentLessonResponse,
  UpdateCourseMarketingDescriptionArgs,
  UpdateCourseMarketingDescriptionResponse,
  UpdateCourseQueryArgs,
  UpdateCourseRequirementsArgs,
  UpdateCourseRequirementsResponse,
  UpdateCourseResponse,
} from './api-course.type';

export const {
  useCreateCourseMutation,
  useReadCourseQuery,
  useLazyReadCourseQuery,
  useReadCoursesQuery,
  useLazyReadCoursesQuery,
  useDeleteCourseMutation,
  useUpdateCourseMutation,
  useUpdateCourseCurrentLessonMutation,
  useUpdateCourseRequirementsMutation,
  useUpdateCourseMarketingDescriptionMutation,
  useReorderLessonsMutation,
  useSubscribeToCourseMutation,
} = api.injectEndpoints({
  endpoints: (builder) => ({
    createCourse: builder.mutation<CreateCourseResponse, CreateCourseQueryArgs>(
      {
        query: ({ payload }) => ({
          url: '/courses',
          method: 'post',
          payload: transformUpdateCoursePayload(payload),
        }),
      },
    ),

    readCourse: builder.query<
      ReturnType<typeof transformResponseReadCourse>,
      ReadCourseQueryArgs
    >({
      query: ({ params: { courseId } }) => ({
        url: `/courses/${courseId}`,
        method: 'get',
      }),
      transformResponse: async (courseResponse: ReadCourseResponse) =>
        updateStorageReadCourse(
          await transformStorageResponseReadCourse(
            transformResponseReadCourse(courseResponse),
          ),
        ),
      providesTags: (_result, error, { params: { courseId } }) =>
        error ? [] : [API_TAGS.course(courseId), API_TAGS.accessCode(courseId)],
    }),

    updateCourse: builder.mutation<UpdateCourseResponse, UpdateCourseQueryArgs>(
      {
        query: ({ payload, params }) => ({
          url: `/courses/${params.courseId}`,
          method: 'patch',
          payload,
        }),
        invalidatesTags: (_result, error, { params: { courseId } }) =>
          error
            ? []
            : [API_TAGS.course(courseId), API_TAGS.accessCode(courseId)],
      },
    ),

    updateCourseCurrentLesson: builder.mutation<
      UpdateCourseCurrentLessonResponse,
      UpdateCourseCurrentLessonArgs
    >({
      query: ({ payload, params }) => ({
        url: `/courses/${params.courseId}/currentLesson`,
        path: '/courses/:courseId/currentLesson',
        params,
        method: 'put',
        payload,
      }),
      invalidatesTags: (_result, error, { params: { courseId } }) =>
        error ? [] : [API_TAGS.course(courseId), API_TAGS.courses()],
    }),

    reorderLessons: builder.mutation<
      ReorderLessonsResponse,
      ReorderLessonsQueryArgs
    >({
      query: ({ params, payload }) => ({
        url: `/courses/${params.courseId}/reorder-lessons`,
        method: 'put',
        payload,
      }),
      invalidatesTags: (_result, error, { params }) =>
        error ? [] : [API_TAGS.course(params.courseId)],
    }),

    updateCourseRequirements: builder.mutation<
      UpdateCourseRequirementsResponse,
      UpdateCourseRequirementsArgs
    >({
      query: ({ payload, params }) => ({
        url: `/courses/${params.courseId}`,
        method: 'patch',
        payload,
      }),
      invalidatesTags: (_result, error, { params: { courseId } }) =>
        error ? [] : [API_TAGS.course(courseId)],
    }),

    updateCourseMarketingDescription: builder.mutation<
      UpdateCourseMarketingDescriptionResponse,
      UpdateCourseMarketingDescriptionArgs
    >({
      query: ({ payload, params }) => ({
        url: `/courses/${params.courseId}`,
        method: 'patch',
        payload,
      }),
      invalidatesTags: (_result, error, { params: { courseId } }) =>
        error ? [] : [API_TAGS.course(courseId)],
    }),

    readCourses: builder.query<ReadCoursesResponse, void>({
      query: () => ({
        url: '/courses/',
        method: 'get',
      }),
      transformResponse: transformStorageResponseReadCourses,
      providesTags: (_result, error) => (error ? [] : [API_TAGS.courses()]),
    }),

    deleteCourse: builder.mutation<DeleteCourseResponse, DeleteCourseQueryArgs>(
      {
        query: ({ params: { courseId } }) => ({
          url: `/courses/${courseId}`,
          method: 'delete',
        }),
        invalidatesTags: () => [API_TAGS.courses()],
      },
    ),

    subscribeToCourse: builder.mutation<
      SubscribeToCourseResponse,
      SubscribeToCourseQueryArgs
    >({
      query: ({ payload }) => ({
        url: '/courseSubscriptions',
        method: 'post',
        payload,
      }),
    }),
  }),
});
